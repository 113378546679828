import SchoolWorker from '@/workers/school?worker';

export const useSchool = defineStore('school', () => {
  // State
  const worker = new SchoolWorker();

  // Getters
  const all = async () => await database.schools.toArray();
  const byId = async (id: string) => await database.schools.get(id);
  const byIds = async (ids: string[]) => await database.schools.bulkGet(ids);
  const hasLifestyleAccess = async (id: string) =>
    (await byId(id))?.lifestyle_enabled ?? false;

  // Actions
  const put = async (school: SchoolModel) => await database.schools.put(school);
  const fetchAll = () =>
    new Promise<void>((resolve, reject) => {
      worker.onmessage = (event: MessageEvent): void => {
        if (event.data.type === 'error' && event.data.status === 401) {
          useUser().logout();
          return reject(new Error('unauthenticated'));
        }

        event.data.type === 'success'
          ? resolve()
          : reject(new Error(event.data.error));
      };

      // Send the cookies and token to the worker.
      worker.postMessage({ type: 'token', token: useUser().token });
      worker.postMessage({ type: 'sync' });
    });

  return {
    worker,

    // Getters
    all,
    byId,
    byIds,
    hasLifestyleAccess,

    // Actions
    put,
    fetchAll,
  };
});
