import axios from 'axios';

export const useAlert = defineStore('alert', () => {
  // State
  const synced: Ref<boolean> = ref(false);

  // Getters
  const all = async () =>
    (await database.alerts.toArray()).filter((alert: AlertModel) => {
      if ((!alert.published_from && !alert.published_to) || alert.dismissed_at)
        return false;

      if (alert.published_from && !alert.published_to)
        return new Date() >= new Date(alert.published_from);
      if (!alert.published_from && alert.published_to)
        return new Date() <= new Date(alert.published_to);

      return (
        new Date() >= new Date(alert.published_from as string) &&
        new Date() <= new Date(alert.published_to as string)
      );
    });

  // Actions
  const sync = async () => {
    try {
      const alerts = (await axios.get('/api/v1/alerts', { timeout: 5000 }))
        ?.data?.data;

      // Retrieve the local alerts.
      const localAlerts = await database.alerts.toArray();

      // Store the alerts in the local database.
      await Promise.all(
        alerts.map((alert: AlertModel) =>
          database.alerts.put({
            ...alert,
            dismissed_at: localAlerts.find(
              (localAlert: AlertModel) => localAlert.id === alert.id,
            )?.dismissed_at,
          }),
        ),
      );

      // Remove any alerts that are no longer in the API.
      await database.alerts
        .where('id')
        .noneOf(alerts.map((alert: AlertModel) => alert.id))
        .delete();
    } catch (e) {
      //
    }

    synced.value = true;
  };
  const dismiss = async (id: string): Promise<void> => {
    const alert = await database.alerts.get(id);
    if (!alert) return;

    await database.alerts.put({ ...alert, dismissed_at: new Date() });
  };

  return {
    synced,

    all,
    sync,
    dismiss,
  };
});
