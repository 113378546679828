import { liveQuery } from 'dexie';
import { useObservable, from } from '@vueuse/rxjs';

export const useGroup = defineStore('group', () => {
  // State
  const groups: Ref<GroupModel[]> = useObservable(
    from(
      liveQuery(async (): Promise<unknown> => await database.groups.toArray()),
    ),
  ) as Ref<GroupModel[]>;

  // Getters
  const all = async () => await database.groups.toArray();
  const byId = async (id: string) => await database.groups.get(id);
  const byIds = async (ids: string[]) => await database.groups.bulkGet(ids);
  const bySchoolId = async (schoolId: string) =>
    await database.groups.where('team_id').equals(schoolId).toArray();
  const status = async (
    group: GroupModel,
    testSession: 1 | 2,
  ): Promise<'open' | 'pending' | 'finished'> => {
    // Check if the group has already completed the test session.
    if (group[`scan_session_${testSession}_completed`]) {
      return 'finished';
    }

    // Retrieve all children of the group.
    const children = await useChild().byGroupId(group.id);

    // Retrieve all tests of the children.
    const statusses = (
      await Promise.all(
        children.map(
          async (child) => await useScanTest().status(child.id, testSession),
        ),
      )
    ).filter((status) => status);

    if (statusses.length === 0) {
      return 'open';
    }
    if (
      statusses.length !== children.length ||
      !group[`scan_session_${testSession}_completed`]
    ) {
      return 'pending';
    }

    return 'finished';
  };
  const session = async (groupId: string): Promise<1 | 2 | null> => {
    // Retrieve the group and school.
    const group = await byId(groupId);
    if (!group) return null;
    const school = await useSchool().byId(group.team_id);
    if (!school) return null;

    let session: 1 | 2 = 1;
    if (group.scan_session_1_completed) session = 2;

    if (session === 1 && school.active_test_session === 2) {
      session = 2;
    }

    return session;
  };
  const scannable = async (groupId: string): Promise<boolean> => {
    const group = await byId(groupId);
    if (!group) return false;

    const school = await useSchool().byId(group.team_id);
    if (!school) return false;

    // If the group has already completed the test session, it cannot scan.
    if (group.scan_session_1_completed && school.active_test_session === 1)
      return false;
    if (group.scan_session_2_completed && school.active_test_session === 2)
      return false;

    return true;
  };

  // Actions
  const put = async (group: GroupModel) => await database.groups.put(group);

  return {
    // State
    groups,

    // Getters
    all,
    byId,
    byIds,
    status,
    session,
    scannable,
    bySchoolId,

    // Actions
    put,
  };
});
