import { v4 } from 'uuid';

export const useQuizAbsence = defineStore('quiz-absence', () => {
  // Getters
  const byId = (id: string) => database.quiz_absences.get(id);
  const bySubmission = (submissionId: string): Promise<AbsenceModel[]> =>
    database.quiz_absences
      .where('submission_id')
      .equals(submissionId)
      .toArray();

  // Actions
  const create = (absence: AbsenceModel) => database.quiz_absences.put(absence);
  const sync = async (
    submissionId: string,
    childIds: string[],
  ): Promise<void> => {
    await database.quiz_absences
      .where('submission_id')
      .equals(submissionId)
      .delete();
    await database.quiz_absences.bulkPut(
      childIds.map((id: string) => ({
        id: v4(),
        submission_id: submissionId,
        child_id: id,
      })),
    );
  };

  return {
    // Getters
    byId,
    bySubmission,

    // Actions
    sync,
    create,
  };
});
