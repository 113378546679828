import { v4 } from 'uuid';

export default function deviceId(): string {
  let id = localStorage.getItem('device_id');

  if (!id) {
    id = v4();
    localStorage.setItem('device_id', id);
  }

  return id;
}
