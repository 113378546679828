import axios from 'axios';
import send from '@/utils/heartbeat';
import { consola } from 'consola';

export default defineNuxtPlugin(async () => {
  consola.info('[system] Initializing...');

  // Disabled.
  return;

  // Setup the Axios library.
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL =
    import.meta.env.VITE_API_URL ??
    'https://backend.healthdevelopmentinstitute.com';
  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  // Send heartbeats every 5 minutes.
  send();
  setInterval(send, 5 * 60 * 1000);

  // Sync the alerts.
  await Promise.all([useAlert().sync(), useUser().fetch()]);

  // Sync the queue.
  useQueue().sync();
  setInterval(() => useQueue().sync(), 5 * 60 * 1000);

  // Sync the system.
  if (useUser().loggedIn) {
    await useSchool()
      .fetchAll()
      .catch(async (error: Error) => {
        if (error.message === 'unauthenticated') {
          await useUser().logout();
          useRouter().push({ name: 'auth.login' });
        }
      });
  }

  consola.success('[system] Ready for takeoff!');
});
