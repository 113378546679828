import axios from 'axios';
import { v4 as uuid4 } from 'uuid';

export const useQuizSubmission = defineStore('quiz-submission', () => {
  /**
   * Getters
   */
  const byId = (id: string) => database.quiz_submissions.get(id);
  const byGroupId = (id: string) =>
    database.quiz_submissions.where('group_id').equals(id).first();
  const byGroupIds = (ids: string[]) =>
    database.quiz_submissions.where('group_id').anyOf(ids).toArray();
  const isStarted = async (id: string) => {
    const teams = await database.quiz_teams
      .where('submission_id')
      .equals(id)
      .toArray();
    return teams.filter((team) => team.finished_at).length > 0;
  };

  /**
   * Actions
   */
  const create = async (
    groupId: string,
    quizId: string,
    type: QuizType,
    progress: SubmissionProgress | null,
  ): Promise<Submission> => {
    const submission = await database.quiz_submissions.add({
      id: uuid4(),
      quiz_id: quizId,
      group_id: groupId,
      completed_at: null,
      created_at: new Date(),
      updated_at: new Date(),
      type,
      progress,
    });

    return (await database.quiz_submissions.get(submission)) as Submission;
  };
  const sync = async () => {
    const submissions = await axios.get('/api/quiz/v1/submissions');

    database.transaction(
      'rw',
      [
        database.quiz_submissions,
        database.quiz_absences,
        database.quiz_answers,
      ],
      async () => {
        // Sync the submissions.
        await Promise.all(
          submissions.data?.data?.map(
            async (submission: Submission) => await store(submission),
          ),
        );

        // Remove the deleted submissions.
        await Promise.all(
          submissions.data?.deleted?.map(
            async (submissionId: string) => await remove(submissionId),
          ),
        );
      },
    );
  };
  const store = async (submission: Submission) => {
    // Store the submission.
    await database.quiz_submissions.put({
      id: submission.id,
      quiz_id: submission.quiz_id,
      group_id: submission.group_id,
      completed_at: submission.completed_at,
      created_at: submission.created_at,
      updated_at: submission.updated_at,
      type: submission.type,
      progress: { team: '', question: 0 },
    });

    // Store the answers.
    await Promise.all(
      (submission.answers as AnswerModel[]).map((answer) =>
        useQuizAnswer().create(answer),
      ),
    );

    // Store the absences.
    await Promise.all(
      (submission.absences as AbsenceModel[]).map((absence) =>
        useQuizAbsence().create(absence),
      ),
    );
  };
  const remove = async (id: string) => {
    await database.quiz_absences.where('submission_id').equals(id).delete();
    await database.quiz_answers.where('submission_id').equals(id).delete();
    await database.quiz_submissions.delete(id);
  };
  const complete = (id: string) =>
    database.quiz_submissions.update(id, { completed_at: new Date() });
  const push = async (submissionId: string) => {
    const submission = await byId(submissionId);
    const answers = await useQuizAnswer().bySubmission(submissionId);
    const absences = await useQuizAbsence().bySubmission(submissionId);

    if (!submission) {
      throw new Error('Submission not found.');
    }

    return axios
      .post('/api/quiz/v1/submissions', {
        id: submission.id,
        quiz_id: submission.quiz_id,
        group_id: submission.group_id,
        completed_at: submission.completed_at,
        absences,
        answers,
      })
      .catch(async (error) => {
        if (!error?.response) {
          throw error;
        }

        // Handle sync conflict statusses.
        switch (error.response.status) {
          // Conflict
          // Store the server version of the submission.
          case 409:
            await remove(submission.id);
            await store(error.response.data.data);
            break;

          default:
            throw error;
        }
      });
  };
  const resume = async (id: string): Promise<object> => {
    const submission = await byId(id);
    if (!submission) throw new Error('Submission not found.');
    if (submission?.completed_at)
      throw new Error('Submission already completed.');

    // Retrieve the data.
    const quiz = await useQuizQuiz().byId(submission.quiz_id);
    const group = await useGroup().byId(submission.group_id);
    if (!group) throw new Error('Group not found.');
    const school = await useSchool().byId(group.team_id);
    if (!school) throw new Error('School not found.');
    const children = await useChild().byGroupId(submission.group_id);
    const absences = await useQuizAbsence().bySubmission(submission.id);
    if (!quiz) throw new Error('Quiz not found.');
    if (!children.length) throw new Error('No children found.');

    // Get a list of absent children id's.
    const absentChildren = absences.map((absence) => absence.child_id);

    // Calculate where the user left off.
    if (submission.type === 'form') {
      // Restore the session.
      const storeSession = useQuizSession();
      storeSession.absent = absentChildren;
      storeSession.group = group;
      storeSession.school = school;
      storeSession.children = children;
      storeSession.submission = submission;
      storeSession.teams = [];

      return {
        name: 'quiz.form',
        params: {
          submissionId: submission.id,
          // @ts-ignore - It does exist.
          childId: submission.progress.childId,
          question: submission.progress.question + 1,
        },
      };
    } else {
      // Restore the session.
      const storeSession = useQuizSession();
      storeSession.absent = absentChildren;
      storeSession.group = group;
      storeSession.school = school;
      storeSession.children = children;
      storeSession.submission = submission;

      return {
        name: 'quiz.quiz',
        params: {
          submissionId: submission.id,
          // @ts-ignore - It does exist.
          team: submission.progress.team,
          question: submission.progress.question + 1,
          view: 'vraag',
        },
      };
    }
  };
  const setProgress = async (id: string, progress: SubmissionProgress) =>
    await database.quiz_submissions.update(id, { progress });

  return {
    // Getters
    byId,
    isStarted,
    byGroupId,
    byGroupIds,

    // Actions
    push,
    sync,
    create,
    remove,
    resume,
    complete,
    setProgress,
  };
});
