<script setup lang="ts">
defineProps<{ error: { statusCode: number } }>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const code = computed(() => +route.params.code);
</script>

<template>
  <!-- <div class="flex h-screen flex-grow flex-col items-center justify-center">
    <div class="space-y-3 text-center">
      <img alt="MQ Logo" src="/images/mq-logo.svg" class="mx-auto h-24 w-24" />

      <h1 class="text-3xl font-bold">
        {{ t('error.title', { code: error.statusCode }) }}
      </h1>

      <p v-if="code === 404">
        {{ t('error.404') }}
      </p>

      <div class="flex justify-center">
        <button
          @click="router.push({ name: 'home' })"
          class="flex items-center justify-center gap-1 font-medium text-orange-600"
        >
          {{ t('error.go-back') }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            fill="currentColor"
            class="h-4 w-4"
          >
            <path
              d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div> -->

  <div class="p-8 text-center max-w-screen-md mx-auto">
    <img src="/icon-192x192.png" alt="MQ Scan" class="max-w-32 mx-auto" />
    <p class="border-b border-gray-300 pb-4">
      We hebben een nieuwe versie van onze app! Ga naar de
      <a
        class="link"
        href="https://play.google.com/store/apps/details?id=nl.twobasics.mqschooltest&hl=nl"
        target="_blank"
        >Google Play Store</a
      >
      of de
      <a
        class="link"
        href="https://apps.apple.com/nl/app/mq-scan-leefstijl-quiz-app/id1137893700"
        target="_blank"
        >Apple App Store</a
      >
      om je app te updaten.
    </p>
    <p class="mb-8 mt-4">
      We've released a new version of our app! Go to the
      <a
        class="link"
        href="https://play.google.com/store/apps/details?id=nl.twobasics.mqschooltest&hl=nl"
        target="_blank"
        >Google Play Store</a
      >
      or the
      <a
        class="link"
        href="https://apps.apple.com/nl/app/mq-scan-leefstijl-quiz-app/id1137893700"
        target="_blank"
        >Apple App Store</a
      >
      to update your app.
    </p>

    <p class="font-semibold text-xl mt-12">Update app via</p>
    <ul class="mx-auto px-4 mt-8 mb-8 grid grid-cols-2 gap-6">
      <!-- Google -->
      <li>
        <a
          href="https://play.google.com/store/apps/details?id=nl.twobasics.mqschooltest&hl=nl"
          target="_blank"
        >
          <img src="/google-play.png" alt="Google Play" />
        </a>
      </li>

      <!-- Apple -->
      <li>
        <a
          href="https://apps.apple.com/nl/app/mq-scan-leefstijl-quiz-app/id1137893700"
          target="_blank"
        >
          <img src="/apple-store.png" alt="Apple Store" />
        </a>
      </li>
    </ul>

    <div class="text-red-500 text-sm">
      <p>Start je app opnieuw op na updaten.</p>
      <p>Restart your app after updating.</p>
    </div>

    <UpdateAvailable />
  </div>
</template>
