export const useQuizAnswer = defineStore('quiz-answer', () => {
  // Getters
  const byId = (id: string) => database.quiz_answers.get(id);
  const bySubmission = (submissionId: string) =>
    database.quiz_answers.where('submission_id').equals(submissionId).toArray();
  const get = (submissionId: string, childId: string, questionId: string) =>
    database.quiz_answers
      .where(['submission_id', 'question_id', 'child_id'])
      .equals([submissionId, questionId, childId])
      .first();
  const byQuestion = (submissionId: string, questionId: string) =>
    database.quiz_answers
      .where(['submission_id', 'question_id'])
      .equals([submissionId, questionId])
      .toArray();

  // Actions
  const create = (answer: AnswerModel) => database.quiz_answers.put(answer);

  return {
    // Getters
    get,
    byId,
    byQuestion,
    bySubmission,

    // Actions
    create,
  };
});
