import { v4 } from 'uuid';

export const useQuizSession = defineStore('quiz-session', () => {
  // State
  const absent: Ref<string[]> = ref([]);
  const group: Ref<GroupModel | null> = ref(null);
  const school: Ref<SchoolModel | null> = ref(null);
  const children: Ref<ChildModel[] | null> = ref(null);
  const wentBackAQuestion: Ref<boolean> = ref(false);
  const submission: Ref<SubmissionModel | null> = ref(null);
  const answers: Ref<{ [key: string]: { [key: string]: string } }> = ref({});

  // Actions
  const pushAnswers = async (
    submissionId: string,
    questionId: string,
    push: { [key: string]: string },
  ) => {
    Object.keys(push).forEach(async (childId: string) => {
      // Retrieve the answer from the database.
      const answer = await useQuizAnswer().get(
        submissionId,
        childId,
        questionId,
      );

      // Store the answer in the local database re-using the id if it already exists.
      await useQuizAnswer().create({
        id: answer?.id || v4(),
        submission_id: submissionId,
        question_id: questionId,
        child_id: childId,
        option_id: push[childId],
      });
    });
  };
  const reset = () => {
    absent.value = [];
    group.value = null;
    answers.value = {};
    school.value = null;
    children.value = null;
    wentBackAQuestion.value = false;
  };

  return {
    // State
    group,
    school,
    absent,
    answers,
    children,
    submission,
    wentBackAQuestion,

    // Actions
    reset,
    pushAnswers,
  };
});
