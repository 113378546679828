import revive_payload_client_4sVQNw7RlN from "/home/forge/app.healthdevelopmentinstitute.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/forge/app.healthdevelopmentinstitute.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/forge/app.healthdevelopmentinstitute.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/forge/app.healthdevelopmentinstitute.com/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/forge/app.healthdevelopmentinstitute.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/forge/app.healthdevelopmentinstitute.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_433a6c95_GHBVffu1e9 from "/home/forge/app.healthdevelopmentinstitute.com/.nuxt/templates.pwa.client.433a6c95.ts";
import i18n_yfWm7jX06p from "/home/forge/app.healthdevelopmentinstitute.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/forge/app.healthdevelopmentinstitute.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_sentry_client_YROiseFzU8 from "/home/forge/app.healthdevelopmentinstitute.com/plugins/1.sentry.client.ts";
import _2_init_qOzE3C3BVn from "/home/forge/app.healthdevelopmentinstitute.com/plugins/2.init.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  templates_pwa_client_433a6c95_GHBVffu1e9,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _1_sentry_client_YROiseFzU8,
  _2_init_qOzE3C3BVn
]