export const useChild = defineStore('child', () => {
  // Getters
  const byId: (id: string) => Promise<ChildModel | undefined> = async (id) =>
    await database.children.get(id);
  const byIds: (ids: string[]) => Promise<(ChildModel | undefined)[]> = async (
    ids,
  ) => await database.children.bulkGet(ids);
  const byGroupId: (groupId: string) => Promise<ChildModel[]> = async (
    groupId,
  ) =>
    await database.children
      .where('group_id')
      .equals(groupId)
      .sortBy('first_name');

  // Actions
  const put = async (child: ChildModel) => await database.children.put(child);

  return {
    // Getters
    byId,
    byIds,
    byGroupId,

    // Actions
    put,
  };
});
