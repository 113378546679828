<script setup lang="ts">
const storeAlert = useAlert();
const alerts: Ref<AlertModel[]> = ref([]);

const dismiss = async (id: string) => {
  await storeAlert.dismiss(id);
  alerts.value = await storeAlert.all();
};

onMounted(async () =>
  storeAlert.synced ? (alerts.value = await storeAlert.all()) : null,
);
watch(
  () => storeAlert.synced,
  () => setTimeout(async () => ((alerts.value = await storeAlert.all()), 50)),
);
</script>

<template>
  <ol v-if="storeAlert.synced" class="shadow-xl">
    <li v-for="alert in alerts" :key="alert.id">
      <Alert :alert="alert" @dismiss="dismiss" />
    </li>
  </ol>
</template>
