<script setup lang="ts">
const { t } = useI18n();
const { $pwa } = useNuxtApp();
</script>

<template>
  <div
    class="fixed bottom-0 left-0 right-0 bg-blue-600"
    v-if="$pwa.needRefresh"
  >
    <Container class="flex items-center justify-between py-4 text-white">
      <p class="text-sm">
        {{ t('updater.update-available') }}
      </p>
      <button @click="$pwa.updateServiceWorker()" class="btn btn--red">
        {{ t('updater.reload') }}
      </button>
    </Container>
  </div>
</template>
