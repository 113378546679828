import { default as _indexv64b9rGRbBMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/_index.vue?macro=true";
import { default as aboutPhHtbPrF5MMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/about.vue?macro=true";
import { default as login29KFidNufRMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/auth/login.vue?macro=true";
import { default as faqDRClvPECkiMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/faq.vue?macro=true";
import { default as indexdcCYlx1cuMMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/index.vue?macro=true";
import { default as _1_46startOJVZNnwE6YMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/form/1.start.vue?macro=true";
import { default as _2_46sessionBiVCBCXuhBMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/form/2.session.vue?macro=true";
import { default as _3_46finishizWjBZdqaDMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/form/3.finish.vue?macro=true";
import { default as formgJGK81YsNgMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/form.vue?macro=true";
import { default as homeVQP9KOddvvMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/home.vue?macro=true";
import { default as onboardingPvRJ7j5jGUMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/onboarding.vue?macro=true";
import { default as prepareLNwz54ePDHMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/prepare.vue?macro=true";
import { default as _1_46create_45teamsOQ94PteFxwMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/quiz/1.create-teams.vue?macro=true";
import { default as _2_46teamsQCsawep2qNMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/quiz/2.teams.vue?macro=true";
import { default as _3_46sessionw25Drl8SdoMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/quiz/3.session.vue?macro=true";
import { default as _4_46results2MUQqLk0qdMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/quiz/4.results.vue?macro=true";
import { default as selectrROg8XmBGVMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/select.vue?macro=true";
import { default as finish2bE56HCoqNMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/scan/finish.vue?macro=true";
import { default as homehF54ibhMZxMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/scan/home.vue?macro=true";
import { default as onboardingSrLLVHkskaMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/scan/onboarding.vue?macro=true";
import { default as prepare1jJ5c6KnRaMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/scan/prepare.vue?macro=true";
import { default as resumeenhNpcikgdMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/scan/resume.vue?macro=true";
import { default as scanMl0Vl5aCp0Meta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/scan/scan.vue?macro=true";
import { default as selectrumesO1GVFMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/scan/select.vue?macro=true";
import { default as settingslQMGYOKAaSMeta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/settings.vue?macro=true";
import { default as systemS4DB1d5bA5Meta } from "/home/forge/app.healthdevelopmentinstitute.com/pages/system.vue?macro=true";
export default [
  {
    name: _indexv64b9rGRbBMeta?.name ?? "_index",
    path: _indexv64b9rGRbBMeta?.path ?? "/_index",
    meta: _indexv64b9rGRbBMeta || {},
    alias: _indexv64b9rGRbBMeta?.alias || [],
    redirect: _indexv64b9rGRbBMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/_index.vue").then(m => m.default || m)
  },
  {
    name: aboutPhHtbPrF5MMeta?.name ?? "about",
    path: aboutPhHtbPrF5MMeta?.path ?? "/about",
    meta: aboutPhHtbPrF5MMeta || {},
    alias: aboutPhHtbPrF5MMeta?.alias || [],
    redirect: aboutPhHtbPrF5MMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/about.vue").then(m => m.default || m)
  },
  {
    name: login29KFidNufRMeta?.name ?? "auth-login",
    path: login29KFidNufRMeta?.path ?? "/auth/login",
    meta: login29KFidNufRMeta || {},
    alias: login29KFidNufRMeta?.alias || [],
    redirect: login29KFidNufRMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: faqDRClvPECkiMeta?.name ?? "faq",
    path: faqDRClvPECkiMeta?.path ?? "/faq",
    meta: faqDRClvPECkiMeta || {},
    alias: faqDRClvPECkiMeta?.alias || [],
    redirect: faqDRClvPECkiMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexdcCYlx1cuMMeta?.name ?? "index",
    path: indexdcCYlx1cuMMeta?.path ?? "/",
    meta: indexdcCYlx1cuMMeta || {},
    alias: indexdcCYlx1cuMMeta?.alias || [],
    redirect: indexdcCYlx1cuMMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: formgJGK81YsNgMeta?.name ?? "quiz-form",
    path: formgJGK81YsNgMeta?.path ?? "/quiz/form",
    children: [
  {
    name: _1_46startOJVZNnwE6YMeta?.name ?? "quiz-form-1.start",
    path: _1_46startOJVZNnwE6YMeta?.path ?? "1.start",
    meta: _1_46startOJVZNnwE6YMeta || {},
    alias: _1_46startOJVZNnwE6YMeta?.alias || [],
    redirect: _1_46startOJVZNnwE6YMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/form/1.start.vue").then(m => m.default || m)
  },
  {
    name: _2_46sessionBiVCBCXuhBMeta?.name ?? "quiz-form-2.session",
    path: _2_46sessionBiVCBCXuhBMeta?.path ?? "2.session",
    meta: _2_46sessionBiVCBCXuhBMeta || {},
    alias: _2_46sessionBiVCBCXuhBMeta?.alias || [],
    redirect: _2_46sessionBiVCBCXuhBMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/form/2.session.vue").then(m => m.default || m)
  },
  {
    name: _3_46finishizWjBZdqaDMeta?.name ?? "quiz-form-3.finish",
    path: _3_46finishizWjBZdqaDMeta?.path ?? "3.finish",
    meta: _3_46finishizWjBZdqaDMeta || {},
    alias: _3_46finishizWjBZdqaDMeta?.alias || [],
    redirect: _3_46finishizWjBZdqaDMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/form/3.finish.vue").then(m => m.default || m)
  }
],
    meta: formgJGK81YsNgMeta || {},
    alias: formgJGK81YsNgMeta?.alias || [],
    redirect: formgJGK81YsNgMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/form.vue").then(m => m.default || m)
  },
  {
    name: homeVQP9KOddvvMeta?.name ?? "quiz-home",
    path: homeVQP9KOddvvMeta?.path ?? "/quiz/home",
    meta: homeVQP9KOddvvMeta || {},
    alias: homeVQP9KOddvvMeta?.alias || [],
    redirect: homeVQP9KOddvvMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/home.vue").then(m => m.default || m)
  },
  {
    name: onboardingPvRJ7j5jGUMeta?.name ?? "quiz-onboarding",
    path: onboardingPvRJ7j5jGUMeta?.path ?? "/quiz/onboarding",
    meta: onboardingPvRJ7j5jGUMeta || {},
    alias: onboardingPvRJ7j5jGUMeta?.alias || [],
    redirect: onboardingPvRJ7j5jGUMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/onboarding.vue").then(m => m.default || m)
  },
  {
    name: prepareLNwz54ePDHMeta?.name ?? "quiz-prepare",
    path: prepareLNwz54ePDHMeta?.path ?? "/quiz/prepare",
    meta: prepareLNwz54ePDHMeta || {},
    alias: prepareLNwz54ePDHMeta?.alias || [],
    redirect: prepareLNwz54ePDHMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/prepare.vue").then(m => m.default || m)
  },
  {
    name: _1_46create_45teamsOQ94PteFxwMeta?.name ?? "quiz-quiz-1.create-teams",
    path: _1_46create_45teamsOQ94PteFxwMeta?.path ?? "/quiz/quiz/1.create-teams",
    meta: _1_46create_45teamsOQ94PteFxwMeta || {},
    alias: _1_46create_45teamsOQ94PteFxwMeta?.alias || [],
    redirect: _1_46create_45teamsOQ94PteFxwMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/quiz/1.create-teams.vue").then(m => m.default || m)
  },
  {
    name: _2_46teamsQCsawep2qNMeta?.name ?? "quiz-quiz-2.teams",
    path: _2_46teamsQCsawep2qNMeta?.path ?? "/quiz/quiz/2.teams",
    meta: _2_46teamsQCsawep2qNMeta || {},
    alias: _2_46teamsQCsawep2qNMeta?.alias || [],
    redirect: _2_46teamsQCsawep2qNMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/quiz/2.teams.vue").then(m => m.default || m)
  },
  {
    name: _3_46sessionw25Drl8SdoMeta?.name ?? "quiz-quiz-3.session",
    path: _3_46sessionw25Drl8SdoMeta?.path ?? "/quiz/quiz/3.session",
    meta: _3_46sessionw25Drl8SdoMeta || {},
    alias: _3_46sessionw25Drl8SdoMeta?.alias || [],
    redirect: _3_46sessionw25Drl8SdoMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/quiz/3.session.vue").then(m => m.default || m)
  },
  {
    name: _4_46results2MUQqLk0qdMeta?.name ?? "quiz-quiz-4.results",
    path: _4_46results2MUQqLk0qdMeta?.path ?? "/quiz/quiz/4.results",
    meta: _4_46results2MUQqLk0qdMeta || {},
    alias: _4_46results2MUQqLk0qdMeta?.alias || [],
    redirect: _4_46results2MUQqLk0qdMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/quiz/4.results.vue").then(m => m.default || m)
  },
  {
    name: selectrROg8XmBGVMeta?.name ?? "quiz-select",
    path: selectrROg8XmBGVMeta?.path ?? "/quiz/select",
    meta: selectrROg8XmBGVMeta || {},
    alias: selectrROg8XmBGVMeta?.alias || [],
    redirect: selectrROg8XmBGVMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/quiz/select.vue").then(m => m.default || m)
  },
  {
    name: finish2bE56HCoqNMeta?.name ?? "scan-finish",
    path: finish2bE56HCoqNMeta?.path ?? "/scan/finish",
    meta: finish2bE56HCoqNMeta || {},
    alias: finish2bE56HCoqNMeta?.alias || [],
    redirect: finish2bE56HCoqNMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/scan/finish.vue").then(m => m.default || m)
  },
  {
    name: homehF54ibhMZxMeta?.name ?? "scan-home",
    path: homehF54ibhMZxMeta?.path ?? "/scan/home",
    meta: homehF54ibhMZxMeta || {},
    alias: homehF54ibhMZxMeta?.alias || [],
    redirect: homehF54ibhMZxMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/scan/home.vue").then(m => m.default || m)
  },
  {
    name: onboardingSrLLVHkskaMeta?.name ?? "scan-onboarding",
    path: onboardingSrLLVHkskaMeta?.path ?? "/scan/onboarding",
    meta: onboardingSrLLVHkskaMeta || {},
    alias: onboardingSrLLVHkskaMeta?.alias || [],
    redirect: onboardingSrLLVHkskaMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/scan/onboarding.vue").then(m => m.default || m)
  },
  {
    name: prepare1jJ5c6KnRaMeta?.name ?? "scan-prepare",
    path: prepare1jJ5c6KnRaMeta?.path ?? "/scan/prepare",
    meta: prepare1jJ5c6KnRaMeta || {},
    alias: prepare1jJ5c6KnRaMeta?.alias || [],
    redirect: prepare1jJ5c6KnRaMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/scan/prepare.vue").then(m => m.default || m)
  },
  {
    name: resumeenhNpcikgdMeta?.name ?? "scan-resume",
    path: resumeenhNpcikgdMeta?.path ?? "/scan/resume",
    meta: resumeenhNpcikgdMeta || {},
    alias: resumeenhNpcikgdMeta?.alias || [],
    redirect: resumeenhNpcikgdMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/scan/resume.vue").then(m => m.default || m)
  },
  {
    name: scanMl0Vl5aCp0Meta?.name ?? "scan-scan",
    path: scanMl0Vl5aCp0Meta?.path ?? "/scan/scan",
    meta: scanMl0Vl5aCp0Meta || {},
    alias: scanMl0Vl5aCp0Meta?.alias || [],
    redirect: scanMl0Vl5aCp0Meta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/scan/scan.vue").then(m => m.default || m)
  },
  {
    name: selectrumesO1GVFMeta?.name ?? "scan-select",
    path: selectrumesO1GVFMeta?.path ?? "/scan/select",
    meta: selectrumesO1GVFMeta || {},
    alias: selectrumesO1GVFMeta?.alias || [],
    redirect: selectrumesO1GVFMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/scan/select.vue").then(m => m.default || m)
  },
  {
    name: settingslQMGYOKAaSMeta?.name ?? "settings",
    path: settingslQMGYOKAaSMeta?.path ?? "/settings",
    meta: settingslQMGYOKAaSMeta || {},
    alias: settingslQMGYOKAaSMeta?.alias || [],
    redirect: settingslQMGYOKAaSMeta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: systemS4DB1d5bA5Meta?.name ?? "system",
    path: systemS4DB1d5bA5Meta?.path ?? "/system",
    meta: systemS4DB1d5bA5Meta || {},
    alias: systemS4DB1d5bA5Meta?.alias || [],
    redirect: systemS4DB1d5bA5Meta?.redirect || undefined,
    component: () => import("/home/forge/app.healthdevelopmentinstitute.com/pages/system.vue").then(m => m.default || m)
  }
]